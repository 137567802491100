/* MAIN CONTIANER */
/* .main-container {
  padding: 50px;
} */

.watchfilmsh2 h2 {
  line-height: 1.2 !important;
}

.box {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
}

.box a {
  transition: transform 0.3s;
}

.box a:hover {
  transition: transform 0.3s;
  -ms-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}

.box img {
  border-radius: 2px;
}

/*-----------------------------------*\
  #DETAIL PAGE
\*-----------------------------------*/

.movie-detail {
  color: white;
}

.detail-content {
  margin-left: 20px;
}

.meta-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.backdrop-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 101%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
  border-radius: 10px;
}

.backdrop-image::after {
  content: "";
  border-radius: 10px;
  position: absolute;
  inset: 0;
  background-image: linear-gradient(
    0deg,
    hsla(250, 13%, 11%, 1),
    hsla(250, 13%, 11%, 0.9)
  );
}

.film-see-all {
  text-align: right;
  margin-right: 16px !important;
  font-size: 16px !important;
  color: #ff5e3a !important;
}

.movie-detail .movie-poster {
  max-width: 300px;
  width: 100%;
}

.movie-detail .heading {
  margin-block: 24px 12px;
}

.movie-detail :is(.meta-list, .genre) {
  color: var(--text-color);
}

.movie-detail .genre {
  margin-block: 12px 16px;
}

.detail-list {
  margin-block: 24px 32px;
}

.movie-detail .list-item {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-block-end: 12px;
}

.movie-detail .list-name {
  color: var(--text-color);
  min-width: 112px;
}

/* screen larger than 768px */
@media (min-width: 768px) {
  /* CUSTOM PROPERTY */
  :root {
    /* gradient color */
    --banner-overlay: 90deg, hsl(220, 17%, 7%) 0%, hsla(220, 17%, 7%, 0) 100%;

    /* font size */
    --fs-heading: 5.4rem;
  }
  /* HOME PAGE */
  .container {
    padding-inline: 24px;
  }

  .slider-list {
    margin-inline: -24px;
  }

  .search-btn {
    display: none;
  }

  .search-box {
    all: unset;
    display: block;
    width: 360px;
  }

  .banner {
    height: 500px;
  }

  .banner-content {
    bottom: 50%;
    transform: translateY(50%);
    left: 50px;
  }

  /* MOVIE DETAIL PAGE */
  .movie-detail {
    display: flex;
    align-items: flex-start;
    gap: 40px;
  }

  .movie-detail .detail-box {
    flex-grow: 1;
  }

  .movie-detail .movie-poster {
    flex-shrink: 0;
    /* position: sticky; */
    /* top: 0; */
  }

  .movie-detail .slider-list {
    margin-inline-start: 0;
    border-radius: var(--radius-16) 0 0 var(--radius-16);
  }

  .movie-detail .slider-inner::before {
    display: none;
  }
}


.img-cover {
  margin-top: 30px;
}

.ml30 {
  margin-left: 30px;
}


.genre-list .wf-title-wrapper {
  margin-block-end: 56px;
}

.wf-grid-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  column-gap: 16px;
  row-gap: 20px;
}

:is(.genre-list, .search-modal) :is(.wf-movie-card, .wf-card-banner) {
  width: 100%;
}


.wf-movie-card {
  position: relative;
  width: 200px;
}

.wf-movie-card .wf-card-banner {
  width: 200px;
}

.wf-movie-card .wf-title {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-block: 8px 4px;
}

.wf-movie-card .wf-meta-list {
  justify-content: space-between;
}

.wf-movie-card .wf-card-btn {
  position: absolute;
  inset: 0;
}
 

:is(.genre-list, .search-modal) :is(.wf-movie-card, .wf-card-banner) {
  width: 100%;
}

.wf-poster-box {
  /* background-image: url("../images/poster-bg-icon.png"); */
  aspect-ratio: 2 / 3;
}

.wf-poster-box,
.video-card {
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center;
  background-color: #313036;
  border-radius: 5px;
  overflow: hidden;
}


.wf-movie-card .wf-card-banner {
  width: 200px;
}


:is(.genre-list, .search-modal) :is(.wf-movie-card, .wf-card-banner) {
  width: 100%;
}


.wf-img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wf-title {
  font-weight: 700;
  letter-spacing: 0.5px;
}

.wf-title {
  font-size: 32px;
}


.wf-meta-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}


.meta-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.movie-detail :is(.wf-meta-list, .genre) {
  color: #959499;
}

.wf-movie-card .wf-meta-list {
  justify-content: space-between;
}


.wf-card-badge {
  background-color: #313036;
  color: #fff;
  font-size:  22.4;
  font-weight: 700;
  padding-inline: 6px;
  border-radius: 4px;
}

.wf-movie-card .wf-card-btn {
  position: absolute;
  inset: 0;
}

.wf-star-rating {
  position: relative;
    bottom: 4px;
}

.see-all-a {
  text-decoration: none !important;
}