body {
  font-family: "Arial", "Helvetica Neue";
  background-color: #f8f8f8 !important;
  overflow-x: hidden;
}

.screnpla-btn {
  line-height: 0px !important;
  height: 30px !important;
}

.g-button {
  background: #000000;
  background-image: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
  border-radius: 10px;
  font-family: Helvetica;
  font-size: 17px;
  color: #ffffff;
  letter-spacing: -0.41px;
  text-align: center;
  line-height: 22px;
  width: 780px;
  height: 38px;
  border: none;
}

.g-button.inverted {
  background: #fff;
  border-radius: 10px;
  font-family: Helvetica;
  font-size: 17px;
  color: #ff5e3a;
  letter-spacing: -0.41px;
  text-align: center;
  line-height: 22px;
  width: 780px;
  height: 50px;
  border: none;
}

.g-button.inverted:hover {
  background-image: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
}

.g-button.cancel {
  background-image: linear-gradient(180deg, #bfbfbf 0%, #999999 100%);
}

.g-button.md {
  line-height: 38px;
  margin-top: 34px;
  margin-bottom: 34px;
  width: 366px;
}

.g-button:hover {
  color: white;
}

.form-control {
  /* height: 54px; */
  width: 100%;
  border: none;
  text-indent: 12px;
  outline: none;
}

.textarea {
  height: 194px;
  width: 100%;
  border: none;
  text-indent: 12px;
  outline: none;
}

.signup {
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: flex-end; /* Horizontally align to right */
  height: 100vh;
  background-image: url("../public/images/signup-background.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  overflow-x: none;
  background-color: #e98429;
}

.message-date {
  position: relative;
  text-align: center;
  margin: 16px 0;
  color: #65676b;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.message-block-options {
  font-size: 14px;
  height: 26px;
  padding: 0;
  border-radius: 15px;
  position: absolute;
  top: 20px;
  cursor: pointer;
  right: 20px;
}

.block-user {
  font-size: 16px;
  margin-right: 6px;
  position: relative;
  top: 2px;
  margin-left: 6px;
}

.message-block-options .popup-content {
  width: 150px !important;
}

.block-user-text {
  font-size: 14px;
}

.card.selected {
  border: 2px solid #e98429; /* Adjust the border style and color as needed */
  box-shadow: 0 0 5px #e9842980; /* Add a shadow effect to the selected card */
}

.signup .panel {
  width: 484px;
  text-align: center;
  margin-top: 30px;
  margin-right: 8%;
  color: #ffffff;
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 30px;
}
.signup .panel #error_explanation {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}
.signup .panel .login {
  border: 1px solid #ffffff;
  border-radius: 10px;
  width: 100%;
  padding: 14px;
}
.signup .panel .login .field {
  font-size: 11px;
}
.signup .panel .login .field a {
  display: block;
  text-align: right;
  color: white;
  width: 382px;
  margin-bottom: 12px;
}
.signup .panel .login input[type="email"],
.signup .panel .login input[type="password"],
.signup .panel .login input[type="text"] {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  font-family: "PingFangHK-Semibold", Helvetica, "Arial Narrow";
  font-size: 17px;
  color: #ffffff;
  letter-spacing: -0.41px;
  line-height: 22px;
  width: 335px;
  height: 46px;
  border: none;
  margin-bottom: 12px;
  padding: 12px;
}
.signup .panel .login ::placeholder {
  color: white;
}
.signup .panel .login .buttons {
  width: 335px;
  margin-top: 20px;
  margin: auto;
  font-size: 17px;
}
.signup .panel .login .buttons a {
  color: #fff;
  font-weight: bold;
}
.signup .panel .login .buttons .btn {
  width: 335px;
  margin-bottom: 12px;
}
.signup .panel .logo {
  width: 100%;
  margin-bottom: 50px;
}
.signup .panel .h2 {
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 0.41px;
  text-align: center;
  line-height: 58px;
  margin-bottom: 6px;
}
.signup .panel .h3 {
  font-size: 40px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 28px;
}
.signup .panel .h4 {
  font-size: 23px;
  font-weight: 300;
  letter-spacing: 1px;
}
.signup .panel .buttons {
  margin-top: 40px;
}
.signup .panel .buttons button {
  width: 332px;
  margin-top: 12px;
  height: 50px;
}
.signup .panel .buttons .btn {
  width: 210px;
  height: 50px;
  background: #000000;
  background: #ffffff;
  border-radius: 10px;
  font-size: 17px;
  color: #1f2532;
  letter-spacing: -0.41px;
  text-align: center;
  line-height: 35px;
}
.signup .panel .buttons .btnpad {
  margin-left: 20px;
}


.suggest-friends {
  /* max-height: 300px; */
  overflow-y: auto;
  overflow-x: hidden;
}

.suggest-friends .requested {
  background-image: linear-gradient(134deg, #3023ae 0%, #c86dd7 100%);
  border-radius: 100px;
  font-family: Arial, Helvetica;
  font-size: 10px;
  color: #ffffff;
  letter-spacing: -0.24px;
  padding: 8px 35px;
}

.sug-frnd-name {
  font-size: 14px;
  margin-left: 10px;
  color: black;
}

.sug-friend {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.1);
  border-radius: 10px;
  padding: 12px;
  margin-right: 12px;
  margin-left: 8px;
  margin-bottom: 10px;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.sug-friend .action-buttons {
  text-align: right;
}

.suggested-frnd-btn {
  background-image: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%) !important;
  color: white !important;  
  border-radius: 6px !important;
  width: 100px !important;
  font-size: 12px !important;
  font-family: "Arial", "Helvetica Neue" !important;
  font-weight: 500 !important;
  padding: 6px;
}

.suggested-cancel-btn {
  background-color: #9e9e9e6b;
  color: #1d1d1d !important;
  margin-right: 5px;
  border-radius: 6px !important;
  width: 100px !important;
  font-size: 12px !important;
  font-family: "Arial", "Helvetica Neue" !important;
  font-weight: 500 !important;
  padding: 6px;
}

.sug-frnd-img {
  border-radius: 10px;
  border: 1px solid #9e9e9e40;
}

.friend-animation {
  transition: transform 0.3s ease-in-out;
}

.friend-info a {
  text-decoration: none ;
}


.hidden {
  opacity: 0;
  transform: translateY(100%);
}

.should-know {
  position: relative;
  left: 10px;
  bottom: 10px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  color: #b0b0b0;
}


/* write media query for mobile screen */

@media (max-width: 768px) {

  /* .signup .panel .logo {
    width: 100%;
    position: relative;
    bottom: 120px;
  } */

  .signup .panel .buttons .btnpad {
    margin-left: 0;
    margin-top: 10px;
  }

  .signup .panel .buttons {
    margin-top: 40px;
    display: inline-grid;
  }

  .signup .panel { 
    margin: auto;
    zoom: 0.8;
  }

  .loginpanel {
    position: relative;
    top: 50px;
  }

  .homelogo {
    position: relative;
    /* bottom: 120px; */
  }

  .signup .panel .login {
    background: #e98429c7;
  }

  .feed .feed-container .feed-center .status-panel {
    width: 96% !important;
  }

  .feed .feed-container .feed-center .feed-panel {
    width: 96% !important;
  }

  .feed .feed-container .feed-center .feed-panel .likes {
    width: 100% !important;
    display: flex !important;
  }
}

.fb-login {
  padding: 0px;
  width: 333px;
  margin-top: 14px;
  cursor: pointer;
  height: 50px;
}

.header {
  background-image: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
  height: 250px;
  text-align: center;
  padding-top: 20px;
}
.header .logo {
  width: 162px;
  height: 66px;
  background-image: url("../public/images/gorilla-logo.png");
  background-size: 100%;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
}

.header.small {
  height: 116px;
}

.header.small.gnav .back {
  color: white;
  font-size: 16px;
  margin-right: 689px;
  margin-top: 16px;
  display: block;
}

.header.small.gnav .logo {
  margin-top: -38px;
}

.header.small.gnav .skip {
  color: white;
  font-size: 16px;
  margin-left: 655px;
  margin-top: -52px;
  display: block;
}

.projects h3 {
  font-size: 20px;
  font-weight: bold;
}

.occupations {
  padding: 18px;
}
.occupations h3 {
  font-size: 18px;
  font-weight: bold;
}
.occupations .occupation-search {
  background: rgba(142, 142, 147, 0.12);
  border-radius: 10px;
  width: 866px;
  height: 36px;
  border: none;
  margin-top: 12px;
  background-image: url("../public/images/search.png");
  background-position: 9px;
  padding-left: 32px;
  background-repeat: no-repeat;
  outline: none;
}
.occupations .occupations-list {
  float: left;
  text-align: center;
  margin-bottom: 20px;
}
.occupations .occupations-list .results {
  height: 600px;
  width: 395px;
  overflow-x: none;
  overflow-y: auto;
  padding-right: 12px;
  margin-top: 14px;
}
.occupations .occupations-list .results a {
  text-decoration: none;
  color: black;
}
.occupations .occupations-list .root-last {
  clear: both;
  margin-bottom: 24px;
}
.occupations .occupations-list .root {
  font-weight: 500;
  font-size: 14px;
  line-height: 69px;
}
.occupations .occupations-list .root .title {
  height: 48px;
  line-height: 29px;
  margin-right: 24px;
  font-size: 14px;
}
.occupations .occupations-list .root .round {
  list-style: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  height: 68px;
  border-bottom: 1px solid #ccc;
  position: relative;
  padding-top: 18px;
  padding-right: 45px;
}
.occupations .occupations-list .root .round :after {
  margin-top: 12px;
}
.occupations .occupations-list .root .round label {
  background-color: #fff;
  border: 1px solid purple;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  right: 11px;
  position: absolute;
  top: 17px;
  width: 28px;
}
.occupations .occupations-list .root .round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 1;
  position: absolute;
  top: -2px;
  transform: rotate(-45deg);
  width: 12px;
}
.occupations .occupations-list .root .round input[type="checkbox"] {
  visibility: hidden;
}
.occupations .occupations-list .root .round .checkbox-on {
  background-image: linear-gradient(-45deg, #3023ae 0%, #c86dd7 100%);
}
.occupations .btn-primary {
  background-image: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
  border-radius: 10px;
  font-family: Helvetica, "Arial-Narrow";
  font-size: 17px;
  color: #ffffff;
  letter-spacing: -0.41px;
  text-align: center;
  line-height: 22px;
  width: 100%;
  border: none;
  height: 50px;
}

.round.round-on {
  background-image: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
  color: white;
}
.round.round-on a {
  color: white !important;
}
.round.round-on .checkbox-on {
  background-image: none !important;
}
.round.round-on .checkbox-on:after {
  border: 2px solid purple !important;
  border-top: none !important;
  border-right: none !important;
}

#OccupationQuestions {
  height: 400px;
  padding: 6px;
  overflow-x: hidden;
  overflow-y: auto;
}

.profile-form {
  width: 780px;
  margin: auto;
  margin-top: -130px;
}
.profile-form .title {
  width: 100%;
  height: 22px;
}
.profile-form .title h2 {
  font-size: 22px;
  color: #ffffff;
  letter-spacing: 0.35px;
  line-height: 28px;
  font-weight: bold;
  font-style: normal;
  float: left;
}
.profile-form .title a {
  float: right;
  color: white;
}
.profile-form .upload .image-upload {
  background: #ffffff;
  border-radius: 10px;
  background-position: center;
  width: 780px;
  height: 220px;
  font-size: 22px;
  color: #d8d8d8;
  letter-spacing: 0.35px;
  font-weight: bold;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  z-index: 8;
}
.profile-form .camera-btn {
  background-image: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
  border-radius: 10px;
  width: 100px;
  height: 100px;
  margin-top: -47px;
  margin-left: 40px;
  z-index: 10;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.profile-form .camera-btn a {
  background-image: url("../public/images/camera-icon.png");
  background-size: 46px 33px;
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
}
.profile-form .prompt {
  font-size: 16px;
  font-weight: normal;
  width: 455px;
  float: right;
  margin-top: -37px;
  color: #1f2532;
  margin-right: 170px;
}

.profile-fields {
  max-width: 780px !important;
  min-width: 780px;
  margin-top: 40px;
}
.profile-fields label {
  font-size: 12px;
  color: #999;
}
.profile-fields h2 {
  font-size: 22px;
  color: #000000;
  letter-spacing: 0.35px;
  line-height: 28px;
  font-weight: bold;
}
.profile-fields .gorilla-form {
  text-align: left;
}
.profile-fields .gorilla-form .occupations-btn {
  height: 54px;
  width: 100%;
  border: none;
  text-indent: 12px;
  outline: none;
}

.modal-lg {
  min-width: 814px;
  margin: auto;
}
.modal-lg .modal-footer {
  border-top: none;
  justify-content: flex-start;
  display: block;
  margin: auto;
  text-align: center;
}
.modal-lg .occupation-search {
  background: rgba(142, 142, 147, 0.12);
  border-radius: 10px;
  width: 758px;
  height: 36px;
  border: none;
  margin-top: 12px;
  background-image: url("../public/images/search.png");
  background-position: 9px;
  padding-left: 32px;
  background-repeat: no-repeat;
  outline: none;
}
.modal-lg .occupation-questions {
  float: right;
}
.modal-lg .occupations-list {
  float: left;
  text-align: center;
  margin-bottom: 20px;
}
.modal-lg .occupations-list .results {
  height: 400px;
  width: 395px;
  overflow-x: none;
  overflow-y: auto;
  padding-right: 12px;
  margin-top: 14px;
}
.modal-lg .occupations-list .root-last {
  clear: both;
  margin-bottom: 24px;
}
.modal-lg .occupations-list .root {
  font-weight: 500;
  font-size: 20px;
  line-height: 69px;
}
.modal-lg .occupations-list .root .title {
  height: 48px;
  line-height: 29px;
  margin-right: 24px;
  font-size: 16px;
}
.modal-lg .occupations-list .root .round {
  list-style: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  height: 68px;
  border-bottom: 1px solid #ccc;
  position: relative;
  padding-top: 18px;
  padding-right: 15px;
}
.modal-lg .occupations-list .root .round :after {
  margin-top: 12px;
}
.modal-lg .occupations-list .root .round label {
  background-color: #fff;
  border: 1px solid purple;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  right: 0;
  position: absolute;
  top: 15px;
  width: 28px;
}
.modal-lg .occupations-list .root .round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 1;
  position: absolute;
  top: -2px;
  transform: rotate(-45deg);
  width: 12px;
}
.modal-lg .occupations-list .root .round input[type="checkbox"] {
  visibility: hidden;
}
.modal-lg .occupations-list .root .round .checkbox-on {
  background-image: linear-gradient(-45deg, #3023ae 0%, #c86dd7 100%);
}

.hidden-upload-field {
  visibility: hidden;
  width: 0px;
  height: 0px;
}

.hobbies {
  height: 224px;
  overflow: auto;
  margin-bottom: 24px;
}
.hobbies ul {
  padding: 0px;
}
.hobbies .bubble {
  width: 164px;
  height: 54px;
  background: rgba(255, 255, 255, 0.92);
  border-radius: 12px;
  list-style: none;
  text-align: center;
  line-height: 51px;
  font-weight: 500;
  color: #ff5e3a;
  text-align: center;
  line-height: 51px;
  float: left;
  margin-right: 24px;
  margin-bottom: 12px;
  cursor: pointer;
}
.hobbies .bubble.on {
  background-image: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
  color: #fff;
}

.voucher-search {
  background: rgba(142, 142, 147, 0.12);
  border-radius: 10px;
  width: 100%;
  height: 50px;
  border: none;
  margin-top: 12px;
  background-image: url("../public/images/search.png");
  background-position: 9px;
  padding-left: 32px;
  background-repeat: no-repeat;
  outline: none;
}

.users-list {
  width: 100%;
  margin-top: 30px;
  border-bottom: 1px solid rgba(142, 142, 147, 0.12);
}
.users-list td {
  padding-bottom: 12px;
}
.users-list td:nth-child(2) {
  margin-left: 20px;
}
.users-list td:nth-child(3) {
  float: right;
}
.users-list td:nth-child(3) button {
  background: #efeff4;
  border-radius: 100px;
  font-family: Arial, Helvetica, "Arial Narrow";
  font-size: 10px;
  border: none;
  color: #1f2532;
  letter-spacing: -0.24px;
  text-align: center;
  width: 131px;
  height: 30px;
  font-family: Arial, Helvetica, "Arial Narrow";
}
.users-list td:nth-child(3) button.requested {
  background-image: linear-gradient(134deg, #3023ae 0%, #c86dd7 100%);
  border-radius: 100px;
  font-family: Arial, Helvetica;
  font-size: 10px;
  color: #ffffff;
  letter-spacing: -0.24px;
}

.projects .occupation-questions {
  float: right;
  height: 600px;
  padding-top: 12px;
  overflow-y: scroll;
}
.projects .occupation-questions .form-control {
  border: 1px solid #eaeaea;
}

.projects .other {
  margin-top: 70px !important;
}

.projects .select-field {
  background: rgba(255, 255, 255, 0.92);
  width: 781px;
  height: 54px;
  margin-bottom: 12px;
  background-image: url("../public/images/location.png");
  background-repeat: no-repeat;
  background-position: 12px;
  padding-top: 16px;
  padding-left: 52px;
}
.projects .select-field a {
  cursor: pointer;
}
.projects .select-field .location {
  float: right;
  color: #999;
  margin-right: 17px;
}

.projects .select-field.resume {
  background-image: url("../public/images/resume.png");
}

.projects .skip {
  color: #999;
}

.projects .url-field {
  float: left;
  width: 97%;
  margin-bottom: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.projects .url-delete {
  float: right;
  margin-top: 5px;
  color: #999;
  cursor: pointer;
}

.projects label {
  font-size: 14px;
  padding-left: 8px;
}

.projects h4 {
  color: #979797;
  font-size: 14px;
  font-weight: normal;
}

.projects .header-panel.large {
  width: 900px !important;
}

.projects .header-panel {
  width: 780px;
  min-height: 285px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: -105px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.projects .header-panel .edit_btn {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px 0 10px 0;
  width: 51px;
  height: 29px;
}
.projects .header-panel .folder {
  margin-bottom: 24px;
}
.projects .header-panel .centered {
  text-align: center;
  width: 100%;
  padding-top: 65px;
}
.projects .header-panel .centered h3 {
  font-weight: unset;
  font-size: 22px;
}
.projects .header-panel .centered #image-field {
  width: 0px;
  height: 0px;
}
.projects .header-panel .centered .project-camera {
  margin-top: 6%;
  background-image: url("../public/images/project-camera.png");
  width: 125px;
  height: 63px;
  display: inline-block;
  background-repeat: no-repeat;
}

.profile-fields {
  margin-left: auto;
  margin-right: auto;
  margin-top: 42px;
}
.profile-fields .hdr {
  margin-bottom: 35px;
}
.profile-fields .hdr h2 {
  float: left;
}
.profile-fields .hdr a {
  float: right;
  color: #666;
}
.profile-fields .hdr i {
  color: green;
}
.profile-fields .reels iframe {
  width: 96% !important;
  height: 415px !important;
}

.project-item {
  height: 335px;
  width: 780px;
  margin-bottom: 24px;
}
.project-item .image {
  background-size: cover;
  cursor: pointer;
  width: 780px;
  height: 285px;
  background: #ffffff;
  border-radius: 10px 10px 0 0;
}
.project-item .image-controls {
  width: 780px;
  height: 50px;
  background: rgba(255, 255, 255, 0.92);
  border-radius: 0px 0px 12px 12px;
  font-size: 10px;
}
.project-item .image-controls .title {
  float: left;
  font-size: 18px;
  margin-top: 11px;
  margin-left: 12px;
}
.project-item .image-controls .icons {
  float: right;
  color: #999999;
  margin-top: 17px;
  margin-right: 15px;
  font-size: 12px;
}
.project-item .image-controls .icons i {
  padding-left: 12px;
}

.project-navs {
  float: left;
  padding-left: 20px;
  margin-top: 12px;
}

.logo-feed {
  width: 140px;
  height: 66px;
  background-image: url("../public/images/gorilla-logo.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  float: left;
  margin-left: 22px;
  margin-top: -18px;
  cursor: pointer;
}

.header.main {
  height: 70px;
}
.header.main .nav-search {
  float: left;
  margin-left: 16px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: #fff;
  border-radius: 30px;
  width: 350px;
  height: 35px;
  outline-color: transparent;
  margin-top: -4px;
  outline-style: none;
  font-size: 14px;
  padding: 8px;
}
.header.main .alerts {
  background-image: url("../public/images/bell.png");
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  background-size: cover;
  float: left;
  margin-left: 21px;
  margin-top: 6px;
}
.header.main .settings {
  background-image: url("../public/images/gear.png");
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  background-size: cover;
  float: right;
  margin-left: 18px;
  margin-top: 6px;
}
.header.main .header-name {
  color: #fff;
  /* float: left; */
  margin-left: 8px;
  margin-top: 0px;
  font-weight: bold;
  width: 127px;
  text-align: left;
  line-height: 40px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.header.main .header-avatar {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  /* float: left; */
  margin-left: 22px;
  border-radius: 50%;
  margin-top: -3px;
  object-fit: cover;
}

.nav-items-right {
  display: inline-block;
  text-align: right;
}

.row-margin0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.header.main .options-btn {
  width: 21px;
  height: 20px;
  float: left;
  margin-top: 3px;
  margin-left: 15px;
}

.user-avatar {
  position: relative;
}

.profile_photo-angleDownIcon {
  background: white;
  position: absolute;
  bottom: 2px;
  color: #525252;
  border-radius: 35px;
  right: -2px;
  margin: auto;
  font-size: 12px;
  padding-top: 1px;
  padding-right: 3px;
  padding-left: 3px;
}

.SP-user-icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  margin: auto;
  background-image: linear-gradient(180deg, #51cf6e 0%, #135d24 100%);
  color: white;
  text-align: center;
  padding-top: 3px;
  margin-right: 12px;
  border-radius: 6px;
}

.header.main ::placeholder {
  /* modern browser */
  color: #fff;
}

.feed .feed-container .feed-left {
  width: 230px;
  float: left;
}

.feed .feed-container .feed-left a {
  border-radius: 3px;
  height: 38px;
  display: flex;
  font-size: 14px;
  color: #1f2532;
  line-height: 36px;
  text-decoration: none;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: 14px 10px;
  margin-bottom: 5px;
}

.feed .feed-container .feed-left a span {
  display: block;
  float: left;
  margin-left: 8px;
  font-weight: 700;
  margin-top: 2px;
}

.news-feed-left-icon {
  font-size: 28px;
  margin-right: 5px;
  margin-top: 5px;
}

.feed .feed-container .feed-left a i {
  color: #999;
  text-orientation: unset;
  font-style: normal;
  float: right;
  margin-right: 10px;
}
.feed .feed-container .feed-left .active-side-menu {
  background: #ff9500;
  border-radius: 3px;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: 14px 10px;
  color: white;
}
.feed .feed-container .feed-left .active-side-menu i {
  color: white;
}
.feed .feed-container .feed-left .edit-profile.active {
  background-image: url("../public/images/edit-profile-white.png");
}
.feed .feed-container .feed-left .edit-profile {
  background-image: url("../public/images/edit-profile.png");
}
.feed .feed-container .feed-left .invitation {
  background-image: url("../public/images/invitation.png");
}
.feed .feed-container .feed-left .investor {
  background-image: url("../public/images/investor.png");
}
.feed .feed-container .feed-left .employee {
  background-image: url("../public/images/employee.png");
}
.feed .feed-container .feed-left .request-review {
  background-image: url("../public/images/request-review.png");
}
.feed .feed-container .status-panel.open {
  height: auto !important;
  transition: height 0.25s ease-in;
  padding-bottom: 12px;
}
.feed .feed-container .status-panel.open .loading {
  display: block;
}
.feed .feed-container .status-panel.open .assets {
  margin-bottom: 6px;
  float: left;
  height: 160px;
  overflow: auto;
  margin-left: 10px;
}
.feed .feed-container .status-panel.open.ready .loading {
  display: none;
}
.feed .feed-container .status-modal .post-questions {
  padding-left: 20px;
  margin-bottom: 20px;
}
.feed .feed-container .status-modal .post-questions .question-group {
  float: left;
  clear: both;
  margin-bottom: 12px;
}
.feed .feed-container .status-modal .post-questions .question-group label {
  clear: both;
  float: left;
  margin-top: 16px;
  margin-bottom: 0px;
  color: #999;
  font-size: 12px;
  height: 36px;
  background-color: #ececec;
  width: 100%;
  padding-top: 15px;
  padding-left: 10px;
  text-transform: uppercase;
}
.feed
  .feed-container
  .status-modal
  .post-questions
  .question-group
  input[type="text"],
.feed
  .feed-container
  .status-modal
  .post-questions
  .question-group
  input[type="date"],
.feed .feed-container .status-modal .post-questions .question-group textarea {
  float: left;
  clear: both;
  width: 100%;
  padding-left: 10px;
  border-left: none;
  border-top: none;
  border-right: none;
  color: #484848;
  outline-color: transparent;
  outline-style: none;
}
.feed .feed-container .status-modal .post-questions .question-group select {
  float: left;
  clear: both;
  width: 500px;
  color: #999;
  border: none;
  height: 40px;
}
.feed .feed-container .status-modal .g-button {
  width: 78%;
  margin-left: 66px;
  display: none;
  margin-top: 12px;
}
.feed .feed-container .status-modal .g-button.on {
  display: block;
}
.feed .feed-container .status-modal .uploaded-image {
  width: 140px;
  height: 140px;
  float: left;
  background-size: cover;
}
.feed .feed-container .status-modal .uploaded-image .checkbox-round {
  display: block;
  width: 1.6em;
  height: 1.6em;
  background-color: transparent;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #fff;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border-width: medium;
  margin-left: 107px;
  margin-top: 6px;
}
.feed .feed-container .status-modal .uploaded-image .checkbox-round:checked {
  background-color: orange;
}
.feed .feed-container .status-modal .loading {
  display: none;
  margin: auto;
  height: 200px;
}
.feed .feed-container .status-modal .ReactModal__Content {
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.1);
  border-radius: 6px;
}
.feed .feed-container .feed-center.large {
  width: 915px;
}
.feed .feed-container .feed-center.large h4 {
  font-weight: bold;
  font-size: 20px;
}
.feed .feed-container .feed-center {
  /* width: 588px; */
  /* float: left; */
  margin-top: 20px;
}
.feed .feed-container .feed-center .status-panel {
  padding-bottom: 20px;
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.1);
  border-radius: 10px;
  width: 50%;
  min-height: 139px;
  margin: auto;
  overflow: hidden;
}
.feed .feed-container .feed-center .status-panel .postas {
  float: right;
  margin: 6px;
  font-size: 14px;
  margin-top: 12px;
  color: #999;
}
.feed .feed-container .feed-center .status-panel .postas select {
  color: #8c8c8c;
  background: #f8f8f8;
  border-radius: 6px;
  /* width: 391px; */
  margin-left: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  color: #999;
  height: 30px;
  margin-top: -6px;
  outline-color: transparent;
  outline-style: none;
}
.feed .feed-container .feed-center .status-panel .profile_photo {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  float: left;
  margin-left: 12px;
  margin-top: 12px;
  object-fit: cover;
}
.feed .feed-container .feed-center .status-panel .textarea {
  float: left;
  border: none;
  margin-left: 12px;
  margin-top: 12px;
  width: 86%;
  height: 57px;
  outline-color: transparent;
  border-radius: 6px;
  resize: none;
  outline-style: none;
  background: #fafafa;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.feed .feed-container .feed-center .status-panel hr {
  float: left;
  margin-top: 7px;
  width: 100%;
  opacity: 0.3;
}
.feed .feed-container .feed-center .status-panel .category {
  height: 60px;
  float: left;
  font-size: 14px;
  margin-top: -31px;
  padding-top: 23px;
  margin-left: 12px;
  cursor: pointer;
}
.feed .feed-container .feed-center .status-panel .category select {
  background: #f8f8f8;
  border-radius: 6px;
  width: 391px;
  margin-left: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  color: #999;
  height: 30px;
  margin-top: -6px;
  outline-color: transparent;
  outline-style: none;
  cursor: pointer;
}
.feed .feed-container .feed-center .status-panel .category .camera-btn {
  float: right;
  margin-left: 6px;
  margin-top: -5px;
  width: 32px;
  height: 32px;
}
.feed .feed-container .feed-center .feed-panel {
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.1);
  border-radius: 10px;
  margin: auto;
  margin-top: 12px;
  width: 50%;
  display: flow-root;
  position: relative;
  top: 12px;
}
.feed .feed-container .feed-center .feed-panel .post-header {
  width: 98%;
  margin: 12px;
  float: left;
}
.feed .feed-container .feed-center .feed-panel .post-header .name {
  float: left;
  margin-left: 12px;
  font-weight: 600;
}
.feed .feed-container .feed-center .feed-panel .post-header .time {
  float: left;
  clear: both;
  margin-left: 51px;
  margin-top: -19px;
  font-size: 14px;
}
.feed .feed-container .feed-center .feed-panel .post-header .time span {
  color: #8c8c8c;
  margin-right: 0.6em;
}

.like-user-div {
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 6px 25.600000381469727px 0px #0000001a;
}
.like-user-div img {
  width: 40px;
  height: 40px;
  margin: 10px;
}

.like-user-title {
  margin-left: 12px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
}

.like-loading-nolike {
  margin: 12px;
  font-size: 14px;
  font-weight: 600;
}

.feed .feed-container .feed-center .feed-panel .post-header a {
  float: left;
}
.feed .feed-container .feed-center .feed-panel .post-header a img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  object-fit: cover;
}
.feed .feed-container .feed-center .feed-panel .body {
  float: left;
  width: 98%;
  margin-left: 12px;
  text-align: left;
  padding-bottom: 12px;
  overflow-wrap: break-word;
  padding-right: 14px;
  white-space: pre-wrap;
}
.feed .feed-container .feed-center .feed-panel .body .assets img {
  border-radius: 10px;
  object-fit: cover;
}
.feed .feed-container .feed-center .feed-panel .body .assets video {
  border-radius: 10px;
}
.feed .feed-container .feed-center .feed-panel .footer {
  float: left;
  height: 40px;
  text-align: left;
  margin-left: 10px;
  padding-top: 6px;

  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 50px;
  border-top: 1px solid #e0e0e0;
  margin-top: 7px;
  padding-top: 9px;
  width: 97%;
}
.feed .feed-container .feed-center .feed-panel .footer ._icon {
  color: #8c8c8c;
  font-size: 18px;
}
.feed .feed-container .feed-center .feed-panel .footer ._icon.on {
  color: #ff9500;
}
.feed .feed-container .feed-center .feed-panel .likes {
  font-family: Arial, Helvetica, "Arial Narrow";
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  color: #8c8c8c;
  letter-spacing: -0.24px;
  line-height: 20px;
  margin-right: 18px;
}
.feed .feed-container .feed-center .feed-panel .promote {
  font-family: Arial, Helvetica, "Arial Narrow";
  text-decoration: none;
  font-size: 20px;
  color: #8c8c8c;
}
.feed .feed-container .feed-center .feed-panel .likes.views {
  float: right;
}
.feed .feed-container .feed-right {
  /* background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.1); */
  border-radius: 10px;
  width: 380px;
  height: 629px;
  float: right;
}
.messages-list {
  padding: 12px;
}
.messages-list h2 {
  font-size: 18px;
  float: left;
  margin-top: 5px;
}
.messages-list .toggle {
  float: right;
}
.messages-list .toggle div {
  width: auto;
  float: left;
  padding: 4px;
}
.messages-list .toggle .on {
  background-image: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
  color: #fff;
  padding: 4px 12px;
}
.messages-list .toggle .off {
  color: #ff9500;
  background-color: #fff;
  cursor: pointer;
  padding: 4px 12px;
}
.messages-list .items {
  float: left;
  width: 100%;
  max-height: 580px;
  overflow-x: auto;
}

/* width */
.messages-list .items::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.messages-list .items::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Track */
.messages-list .items::-webkit-scrollbar-track:hover {
  background: rgb(236, 236, 236);
  border-radius: 10px;
}

/* Handle */
.messages-list .items::-webkit-scrollbar-thumb {
  background: #e4e6eb;
  border-radius: 10px;
}

/* Handle on hover */
.messages-list .items::-webkit-scrollbar-thumb:hover {
  background: #e4e6eb;
}

.messages-list .message {
  margin-top: 12px;
  clear: both;
  height: 70px;
  position: relative;
  float: left;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.1);
  border-radius: 10px;
  padding: 4px;
}

.popup-new-chat {
  position: absolute;
  right: 36px;
  top: 20px;
}

.popup-new-chat p {
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  color: #000;
  /* text-decoration: underline; */
}
.messages-new-chat p {
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  color: #000;
  /* text-decoration: underline; */
}

.messages-list .message .profile_photo {
  float: left;
  width: 40px;
  height: 40px;
  background-size: cover;
  border-radius: 50px;
  position: relative;
  top: 10px;
  left: 5px;
}
.messages-list .message .recipients {
  margin-top: 18px;
  margin-right: 10px;
  max-width: 99%;
  position: absolute;
  top: 15px;
  right: 0px;
  display: flex;
  direction: rtl;
}

.no-group {
  font-size: 12px;
  margin-top: 60px;
  text-align: center;
  font-weight: 600;
  color: #999;
}

.message-timestamp {
  font-size: 10px;
  float: right;
  margin-top: 12px;
  font-weight: 500;
}

.messages-list .message .recipients .profile_photo {
  float: left;
  width: 20px;
  height: 20px;
  background-size: cover;
  border-radius: 50%;
}
.messages-list .message .info {
  float: left;
  margin-left: 12px;
  cursor: pointer;
  width: 51%;
}
.messages-list .message .created {
  float: right;
  margin-left: 12px;
  width: 29%;
}
.messages-list .message .created .ago {
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  right: 10px;
  top: 4px;
}

.crew-label {
  font-weight: 600;
  display: block;
  margin-top: 10px;
}

.messages-list .message .info .name {
  float: left;
  font-weight: 600;
  font-size: 14px;
}
.messages-list .message .info .body {
  clear: both;
  float: left;
  font-size: 12px;
  text-overflow: ellipsis;
  width: 280px;
  /* height: 20px; */
  overflow: hidden;
  color: #898989;
  white-space: nowrap;
  margin-top: -2px;
}

.messages {
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.1);
  border-radius: 3px;
  margin-top: 12px;
  width: 100%;
  float: left;
  margin-top: 0px;
  min-height: 200px;
  padding: 12px;
}

.message-header {
  width: 100%;
  border-bottom: 1px solid #bababa;
  height: 46px;
  margin-top: 10px;
}

.messages .message-header img {
  width: 35px;
  height: 35px;
  float: left;
  border-radius: 5px;
  margin-left: 6px;
}
.messages .message-header h2 {
  font-size: 15px;
  float: left;
  margin-left: 6px;
  margin-top: 0px;
  border-radius: 5px;
  font-weight: 600;
}
.messages .message-list {
  min-height: 205px;
  position: relative;
  padding-bottom: 24px;
}
.messages .message-list .inner-list {
  width: 100%;
  padding-right: 12px;
  overflow-y: auto;
  height: 70vh;
  padding-top: 18px;
  margin-bottom: 25px;
}
.messages .message-list .sender-avatar {
  width: 18px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 18px;
  clear: both;
  margin-bottom: 8px;
  border-radius: 100%;
  margin-top: -10px;
  margin-right: -4px;
}
.messages .message-list .sender-avatar.left {
  float: left;
  margin-top: -6px;
}
.messages .message-list .sender-avatar.right {
  float: right;
}
.messages .message-list .bubble {
  background-image: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
  border-radius: 20px 20px 5px 20px;
  font-family: Arial, Helvetica, "Arial Narrow";
  font-size: 16px !important;
  line-height: 18px !important;
  position: relative !important;
  color: #ffffff;
  letter-spacing: -0.41px;
  line-height: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 80% !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  float: right;
  clear: both;
  margin-bottom: 4px;
  white-space: pre-wrap;
}
/* .messages .message-list .bubble::before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}
.messages .message-list .bubble::after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
} */

.messages .message-list .reply {
  background: rgb(237 237 237 / 92%);
  border-radius: 5px 20px 20px 20px;
  font-family: Arial, Helvetica;
  position: relative;
  font-size: 16px !important;
  line-height: 18px !important;
  color: #1f2532;
  margin-left: 5px;
  letter-spacing: -0.41px;
  line-height: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 80% !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  float: left;
  clear: both;
}

.shared-post {
  padding: 0px 30px;
  background: #e0e0e08a;
  border-radius: 7px;
  position: relative;
  right: 8px;
}

/* .messages .message-list .reply::before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: rgb(237 237 237 / 92%);
  border-bottom-right-radius: 15px;
}
.messages .message-list .reply::after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
} */

.message-lastConversation {
  position: relative;
  font-size: 12px;
  left: 6px;
  bottom: 8px;
  color: #9aa4ae;
}

.message-block-btn {
  width: 90px;
  font-size: 14px;
  height: 26px;
  padding: 0;
  border-radius: 15px;
  position: absolute;
  top: 14px;
  right: 18px;
}

.message-attachement {
  background: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
  color: white !important;
  border-radius: 50%;
  position: relative;
  top: 5px;
  padding-left: 6px;
  padding-top: 5px;
  padding-right: 6.5px;
  padding-bottom: 7px;
}

.messages .emojibtn {
  float: left;
  padding-right: 6px;
}
.messages .attachment-prev {
  height: 82px;
  margin-bottom: 6px;
}
.messages .attachment-prev .img {
  height: 50px;
  margin-right: 24px;
  width: 60px;
  background-size: cover;
  float: left;
}

.container {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.messages .attachment-prev .img i {
  float: right;
  margin-right: -17px;
  cursor: pointer;
  font-size: 12px;
}
.messages .message-field {
  position: absolute;
  bottom: 0;
  float: left;
  clear: both;
  display: flex;
  margin-top: 12px;
  width: 100%;
}
.messages .message-field .attachment {
  color: black;
}
.messages .message-field #message-text {
  color: #000000;
  letter-spacing: -0.41px;
  line-height: 22px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.06);
  font-size: 14px;
  outline-color: transparent;
  resize: none;
  outline-style: none;
  width: 84%;
  float: left;
  padding: 3px 12px;
  margin-right: 6px;
}
.messages .message-field img {
  float: right;
  cursor: pointer;
  width: 28px;
  height: 28px;
  position: relative;
  top: 10px;
  left: 8px;
}

.alerts-count {
  background-color: red;
  color: #fff;
  border-radius: 100%;
  float: left;
  position: absolute;
  text-align: center;
  width: 15px;
  height: 15px;
  font-size: 9px;
  right: 12px;
  padding-top: 1px;
}

.alerts-header {
  float: right;
  height: 27px;
  color: #ff5e3a;
  margin-right: 6px;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
}

.alerts-control {
  float: left;
  width: 42px;
  cursor: pointer;
}
.alerts-control .popup-content {
  width: 376px !important;
  height: 530px;
  background: #fff;
  border-radius: 10px !important;
  border: none !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.alerts-control .popup-overlay {
  background-color: #000;
  opacity: 0.3;
  z-index: 2;
}
.messages-control .popup-content {
  width: 376px !important;
  height: 530px;
  background: #fff;
  border-radius: 10px !important;
  border: none !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.messages-control .popup-overlay {
  background-color: #000;
  opacity: 0.3;
  z-index: 2;
}
.alerts-control .del {
  float: right;
  margin-top: -43px;
  margin-right: 17px;
  color: #ccc;
  z-index: 20;
}
.alerts-control .alerts-panel {
  padding-top: 12px;
  padding-bottom: 12px;
  height: 487px;
  overflow-y: auto;
  float: left;
  z-index: 19;
}
.alerts-control .alerts-panel .alert-item {
  cursor: pointer;
  /* width: 100%; */
  height: 70px;
  background: #ffffff;
  border-bottom: 1px solid #e8e8e8;
  float: left;
  margin-bottom: 12px;
  border-radius: 6px;
  margin-left: 12px;
}

.alert-item-unread {
  background-color: #f1f0f6 !important;
}

.confirmation-modal-cancel {
  margin-right: 10px;
  padding: 5px 20px;
  font-size: 14px;
  border-radius: 5px;
  background-color: gray;
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  width: 100px;
  height: 35px;
  margin-top: 10px;
}

.confirmation-modal-submit {
  margin-right: 10px;
  padding: 5px 20px;
  font-size: 14px;
  border-radius: 5px;
  background: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  width: 100px;
  height: 35px;
  margin-top: 10px;
}

.modal-open {
  overflow: hidden;
}

.alerts-control .alerts-panel .alert-item .profile_photo {
  width: 50px;
  height: 50px;
  background-size: cover;
  border-radius: 50%;
  float: left;
  margin: 12px;
}
.alerts-control .alerts-panel .alert-item .name {
  float: right;
  font-size: 13px;
  height: 30px;
  line-height: 16px;
  overflow: hidden;
  /* margin-top: -51px; */
  text-align: left;
  width: 260px;
  margin-top: 12px;
}
.alerts-control .alerts-panel .alert-item .time {
  float: left;
  clear: both;
  font-size: 12px;
  position: relative;
  margin-left: 63px;
  margin-top: -19px;
  top: -12px;
  left: 12px;
  font-weight: 600;
  color: #ff5e3a;
}

.feed-panel-panel.on {
  display: block;
}

.feed-comment-panel {
  width: 100%;
  display: block;
  float: left;
  clear: both;
}
.feed-comment-panel .comment-item {
  width: 100%;
  float: left;
  margin-left: 16px;
  clear: both;
}
.feed-comment-panel .comment-item .comment-avatar {
  width: 30px;
  height: 30px;
  float: left;
  background-size: cover;
  border-radius: 3px;
}
.feed-comment-panel .comment-item .comment-body {
  float: left;
  font-size: 14px;
  width: 85%;
  margin-left: 12px;
  margin-bottom: 22px;
  line-height: 18px;
}
.feed-comment-panel .comment-form {
  float: left;
  margin-left: 60px;
  width: 87%;
  margin-bottom: 12px;
}
.feed-comment-panel .comment-form .comment-avatar {
  width: 30px;
  height: 30px;
  float: left;
  background-size: cover;
  border-radius: 3px;
}
.comment-avatar {
  width: 30px;
  height: 30px;
  float: left;
  background-size: cover;
  border-radius: 3px;
}
.new-comment-body {
  margin-left: 40px;
  font-size: 14px;
}
.new-comment-item {
  padding: 7px;
  position: relative;
  margin-bottom: 6px;
  border-radius: 10px;
  box-shadow: 0px 6px 20.600000381469727px 0px #0000001a;
}

.replies {
  margin: 12px;
  border-top: 1px solid #c9c9c982;
  padding-top: 12px;
}

.reply-body {
  margin-left: 40px;
}

.reply-item {
  display: block;
  margin-bottom: 5px;
}

.comment-edit-icons {
  position: absolute;
  right: 20px;
  top: 2px;
  font-size: 12px;
}

.comment-edit-icons i {
  margin-right: 5px;
  cursor: pointer;
}

.comment-actions {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 12px;
  cursor: pointer;
}

.comment-actions .popup-content {
  width: 125px !important;
  padding: 12px !important;
  z-index: 99999999 !important;
  background: #fff;
  border-radius: 3px !important;
  border: none !important;
}

.comment-like-btn {
  font-size: 12px;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
  top: 4px;
}

.send-comment-icon {
  width: 30px;
  cursor: pointer;
  height: 30px;
  margin-left: 10px;
}

.friend-req-btns {
  display: flex;
  margin-right: 30px;
}

.frnd-req-accept-btn {
  background: #4caf50 !important;
  color: white !important;
  font-weight: 700;
  width: 100px !important;
  font-size: 11px;
  margin-right: 10px !important;
}

.frnd-req-reject-btn {
  background: #e74c3c !important;
  color: white !important;
  font-weight: 700;
  width: 100px !important;
  font-size: 11px;
}

.frnd-req-remove-btn {
  background: gray !important;
  color: white !important;
  margin-right: 30px !important;
  font-weight: 700;
  width: 100px !important;
  font-size: 11px;
}

.feed-comment-panel .comment-form .reply {
  width: 92%;
  margin-left: 43px;
  outline-color: transparent;
  outline-style: none;
  font-size: 13px;
  padding: 5px;
  border: 1px solid #999;
}
.feed-comment-panel .comment-form ::placeholder {
  font-size: 12px;
}

.filter-options .popup-content {
  width: 250px !important;
  padding: 12px !important;
  background: #fff;
  border-radius: 3px !important;
  border: none !important;
}
.filter-options .popup-content .g-button {
  width: 225px;
  height: 37px;
  font-size: 15px;
  margin-top: 12px;
}
.filter-options .popup-content .filters {
  text-align: left;
  width: 100%;
}
.filter-options .popup-content .filters .filter-items {
  float: left;
  clear: both;
  height: 30px;
  border-bottom: 1px solid #dadada;
  margin-top: 12px;
}
.filter-options .popup-content .filters .filter-items label {
  float: left;
  width: 220px;
  text-align: left;
  line-height: 12px;
}
.filter-options .popup-content .filters .filter-items input[type="radio"] {
  float: right;
  outline-color: transparent;
  outline-style: none;
  margin-top: -23px;
  appearance: none;
  -webkit-appearance: none;
  -mozilla-appearance: none;
  vertical-align: text-bottom;
  border: 1px solid #ff9500;
  border-radius: 100%;
  height: 23px;
  width: 23px;
}
.filter-options
  .popup-content
  .filters
  .filter-items
  input[type="radio"]:checked {
  border-width: 12px;
  height: 23px;
  width: 23px;
}
.filter-options
  .popup-content
  .filters
  .filter-items
  input[type="radio"]:checked:before {
  content: "";
  height: 10px;
  width: 10px;
  display: block;
}

a.new-message {
  color: #fff;
  margin-left: -16px;
  margin-top: 4px;
  font-size: 21px;
  display: inline-block;
}

.recip-panel {
  background: #ffffff;
  margin-top: 12px;
  width: 100%;
  position: relative;
  bottom: 14px;
  display: flex;
  float: left;
  margin-top: 0px;
}

.close-search {
  position: absolute;
  right: 5px;
  top: 10px;
  cursor: pointer;
}

.new-recip-panel .recip-bubble {
  font-size: 12px;
  float: left;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #fe9956;
  border-radius: 11px;
  color: #fff;
  margin-left: 4px;
  margin-bottom: 4px;
  cursor: pointer;
}
.recip-panel label {
  float: left;
  margin-top: 4px;
  margin-right: 6px;
}
.recip-panel .recip-input {
  color: #000000;
  border-radius: 6px;
  letter-spacing: -0.41px;
  line-height: 25px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.06);
  height: 30px;
  font-size: 14px;
  outline-color: transparent;
  resize: none;
  outline-style: none;
  width: 100%;
  padding-left: 12px;
  float: right;
  margin-bottom: 12px;
}
/* .new-recip-panel .recip-results {
  margin-top: 79px;
  margin-left: 1px;
} */

.recip-remove-icon {
  margin-left: 5px;
  position: relative;
  bottom: 1px;
}

.new-recip-panel .recip-results .recip-item {
  width: 94%;
  height: 49px;
  float: left;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  padding-left: 49px;
  padding-top: 6px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 9px;
  cursor: pointer;
}


.recip-instructions {
  position: relative;
  bottom: 18px;
  left: 28px;
  font-size: 12px;
  color: red;
  height: 0px;
}

.settings-pop {
  cursor: pointer;
}
.settings-pop .popup-content {
  border: none !important;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  border-radius: 5px !important;
}

.SP-user-profile-img {
  border-radius: 50px;
  width: 35px !important;
  height: 35px !important;
}

.nav-right-content {
  /* float: right; */
  text-align: right;
  justify-content: right;
  display: flex;
  margin-right: 12px;
}

.header-messages {
  margin-right: -10px;
  position: relative;
}

.header-msg-icon {
  background: white;
  padding: 10px;
  color: #525252;
  border-radius: 50px;
}

.header-bell-icon {
  background: white;
  padding: 10px 11px;
  margin-right: 22px;
  color: #525252;
  border-radius: 50px;
}

.settings-panel {
  width: 375px;
  margin-left: 4px;
}
.settings-panel .sep {
  border-top: 1px solid #eaeaea;
  float: left;
  width: 74%;
  margin-left: -10px;
}
.settings-panel .label {
  float: left;
  font-size: 14px;
  clear: both;
  margin: 6px;
  text-align: left;
  width: 65%;
  text-align: left;
  height: 34px;
  padding-top: 2px;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
.settings-panel .label img {
  width: 28px;
  height: 28px;
  margin-right: 12px;
}
.settings-panel .label .switch {
  width: 42px !important;
  height: 26px !important;
  margin-left: 67px;
}

.med-input {
  height: 40px;
}

.g-button.full {
  width: 100% !important;
}

.g-button.w-177 {
  width: 170px !important;
  margin-right: 20px;
}

.autosearch {
  width: 100%;
  background-color: #fff;
}

.nooutline:focus {
  outline: none;
  outline: none;
}

.nooutline:active {
  outline: none;
}

.nooutline {
  outline: none;
}

.ctype-bubbles {
  width: 100%;
  float: left;
  margin-bottom: 6px;
}
.ctype-bubbles .ct-bubble {
  color: #fff;
  padding: 8px;
  border-radius: 21px;
  width: 208px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  margin-right: 6px;
  margin-bottom: 6px;
  background-image: linear-gradient(-45deg, #3023ae 0%, #c86dd7 100%);
}

.company-list-item {
  margin-bottom: 6px;
  float: left;
  clear: both;
  width: 400px;
}
.company-list-item img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  float: left;
}
.company-list-item a {
  color: black;
}
.company-list-item i {
  cursor: pointer;
  float: left;
  margin-left: 80%;
  margin-top: -15px;
}

.profile-tabs {
  background-color: #fff;
  width: 100%;
  height: 80px;
  margin-top: 12px;
  border-radius: 4px;
  box-shadow: 0 2px 0px 0 rgba(47, 83, 151, 0.1);
}
.profile-tabs ul {
  list-style: none;
  padding-top: 24px;
  padding-left: 0px;
}
.profile-tabs ul li.active {
  background-image: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
  background-repeat: no-repeat;
  background-position-y: 51px;
  background-position-x: 73px;
  background-size: 107px 5px;
}
.profile-tabs ul li {
  font-size: 12px;
  display: inline-block;
  text-align: center;
  width: 246px;
  height: 56px;
}
.profile-tabs ul li a {
  color: black;
}
.profile-tabs ul li a i {
  font-size: 18px;
}
.profile-tabs ul li a.active {
  color: #ff9500;
}

.profile-sub-tab {
  height: 40px;
  padding: 0px;
}
.profile-sub-tab li {
  float: left;
  list-style: none;
  width: 195px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0px;
}
.profile-sub-tab li.active {
  color: #ff9500;
  text-decoration: underline;
  font-weight: bold;
}

.project-item-box {
  border-radius: 4px;
  box-shadow: 0 2px 0px 0 rgba(47, 83, 151, 0.1);
  width: 100%;
  background-color: #fff;
  float: left;
  margin-bottom: 16px;
}
.project-item-box .poster {
  background-size: cover;
  width: 100%;
  height: 320px;
  border-radius: 4px 4px 0px 0px;
}
.project-item-box .meta {
  font-size: 14px;
  margin-left: 12px;
}
.project-item-box .title {
  margin: 12px;
  font-weight: 600;
}
.project-item-box .description {
  margin: 12px;
  font-size: 14px;
}

.profile-id {
  text-shadow: 0px 1px 1px #000000;
  float: left;
  margin-top: -51px;
  color: white;
  /* font-weight: bold; */
  font-size: 24px;
  margin-left: 12px;
  line-height: 22px;
}

input.readonly {
  background-color: #f8f8f8;
}

.profile-form .prompt {
  white-space: nowrap;
}

.profile-form .rating {
  float: left;
}
.profile-form .rating .fas {
  color: #ff9500;
}

.profile-form .ctrls {
  float: left;
  margin-right: 20px;
}
.profile-form .ctrls button {
  background-image: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
  border-radius: 10px;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  padding: 4px 8px;
  margin-right: 4px;
}

label.question {
  text-transform: lowercase;
}

label.question::first-letter {
  text-transform: uppercase;
}

.item-controls {
  float: right;
  color: #8c8c8c;
}
.item-controls .fa-edit {
  cursor: pointer;
  margin-right: 12px;
}
.item-controls .fa-trash {
  cursor: pointer;
}

.add-new-sub-tab {
  display: inline;
  float: right;
  margin-right: 16px;
  margin-bottom: 12px;
  color: green;
}

.g-button.full.thin {
  height: 34px !important;
  width: 100%;
}

.post-controls {
  float: right;
  margin-right: 11px;
  color: #c7c7c7;
}
.post-controls i {
  margin-left: 8px;
  cursor: pointer;
}

.actionbtn {
  outline: none;
  border: none;
}

.actionbtn:focus {
  outline: none;
}

.DayPicker {
  width: 100%;
  background-color: #fff;
}
.DayPicker .DayPicker-Month {
  width: 100%;
}
.DayPicker .DayPicker-Day {
  padding: 27px;
  outline: none;
}
.DayPicker .DayPicker-Day.DayPicker-Day--selected {
  background-color: red;
  outline: none;
}
.DayPicker .DayPicker-Day.DayPicker-Day--selected:hover {
  background-color: #ccc;
  outline: none;
}

.react-tel-input .form-control {
  width: 100%;
  border: none;
}

.form-control[type="range"] {
  padding: 0 !important;
}

.promote-options {
  background-color: #fff;
  width: 100%;
  float: left;
  padding-bottom: 12px;
}
.promote-options h4 {
  margin-left: 24px;
  margin-bottom: 12px;
}
.promote-options .price {
  padding-top: 12px;
  padding-left: 17px;
  font-weight: bold;
  font-size: 18px;
  width: 85%;
  height: 53px;
  background-color: #efefef;
  margin: auto;
  border-radius: 5px;
  border: 2px solid #efefef;
  -webkit-box-shadow: 0px 5px 0px 0px #ff910b;
  color: #000;
  cursor: pointer;
  -moz-box-shadow: 0px 5px 0px 0px #ff910b;
  box-shadow: 0px 5px 0px 0px #ff910b;
  margin-bottom: 24px;
}

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 588px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.load-more {
  color: #8c8c8c;
  text-align: center;
  padding: 1em;
  cursor: pointer;
  clear: both;
}

.user-search-result {
  float: left;
  cursor: pointer;
  margin-bottom: 12px;
  padding: 6px;
  border-bottom: 1px solid #eaeaea;
  clear: both;
  width: 100%;
}
.user-search-result .profile_photo {
  float: left;
}
.user-search-result .profile_photo img {
  height: 40px;
  width: 40px;
  float: left;
  margin-right: 6px;
}
.user-search-result h2 {
  font-size: 18px;
  margin-bottom: 1px;
}
.user-search-result .info {
  font-size: 14px;
}

.comment-edit {
  font-size: 12px;
  color: blue;
  cursor: pointer;
  display: table-cell;
  padding-right: 6px;
}

.tagging {
  width: 96%;
  height: 30px;
  float: left;
  font-size: 12px;
  font-weight: bold;
  padding: 6px;
}
.tagging .tagger .tag-item {
  cursor: pointer;
  float: left;
  margin-left: 12px;
}
.tagging .tagger .tag-item img {
  width: 23px;
  height: 23px;
}
.tagging .tagger .tag-item label {
  cursor: pointer;
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block;
  /* For Firefox */
}

.modalHeader {
  width: 100%;
  height: 50px;
  background-image: linear-gradient(180deg, #ff9500 0%, #ff5e3a 100%);
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
}
.modalHeader .modalClose {
  float: right;
  cursor: pointer;
}

.modalContent {
  padding: 10px;
  max-height: 400px;
  overflow-y: auto;
}

.reviewList {
  padding: 0px;
}
.reviewList li {
  list-style: none;
  float: left;
  margin-bottom: 11px;
}
.reviewList .profile_photo {
  width: 30px;
  height: 30px;
  background-size: cover;
  float: left;
}
.reviewList .name {
  float: left;
  font-weight: bold;
  margin-left: 6px;
  margin-top: 2px;
  width: 90%;
}
.reviewList .review {
  font-size: 14px;
  margin-left: 37px;
  width: 88%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 12px;
  float: left;
}

#review-btn {
  border: none;
}

.seeking label {
  font-size: 13px;
  color: #999;
}

.react-datepicker-wrapper {
  display: block;
  clear: both;
}
.react-datepicker-wrapper input {
  height: 40px;
  width: 100%;
  border: none;
  text-indent: 12px;
}

.tagged {
  color: #ff9500;
  font-weight: bold;
}

.tagged:hover {
  color: #ff9500;
  font-weight: bold;
}

.friend-item {
  float: left;
  clear: both;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
}
.friend-item .profile_photo {
  margin-top: 8px;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: cover;
}
.friend-item .info {
  float: left;
  margin-left: 50px;
  width: 430px;
  margin-top: 0px;
  margin-bottom: 12px;
  margin-bottom: 20px;
}

.remaining {
  float: left;
  margin-left: 6px;
  background-color: #ff9500;
  width: 20px;
  font-size: 12px;
  height: 20px;
  border-radius: 17px;
  color: #fff;
  margin-top: 7px;
  line-height: 22px;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
}

.react-modal {
  border: none;
}

.search-preview {
  display: none;
  background-color: #fff;
  width: 494px;
  height: 280px;
  position: absolute;
  margin-left: 227px;
  margin-top: 35px;
  overflow-y: scroll;
  z-index: 100;
}

.more-dots {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  margin-left: 30px;
  font-size: 17px;
  cursor: pointer;
}

.taggingfield {
  height: unset;
  overflow-y: auto;
  height: 60px;
}

.photo-edit-thumb {
  width: 94px;
  height: 94px;
  float: left;
  margin-right: 12px;
  margin-bottom: 12px;
  background-size: cover;
}
.photo-edit-thumb .fa-trash {
  color: #ff5e3a;
  margin-top: 68px;
  margin-left: 65px;
  cursor: pointer;
  border: 8px solid #fff;
  background-color: #fff;
  border-radius: 25px;
}

.photo-edits {
  float: left;
  margin-top: 30px;
  width: 100%;
  margin-bottom: 20px;
}

.hidden {
  display: none;
}

.react-select-styled div div {
  border: none;
}

/* ------------- Loading Spinner ------------- */

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.61
  ); /* Light background color with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the spinner appears on top of other content */
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ff5e3a;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ------------- Loading Spinner ------------- */



.auth-intro-text {
  width: 80%;
  text-align: justify;
  margin-left: 10%;
  margin-top: 5%;
  color: white;
}


.auth-intro-text-home {
  width: 80%;
  text-align: justify;
  margin-left: 10%;
  margin-top: 5%;
  color: white;
}
.auth-intro-text-signup {
  width: 80%;
  text-align: justify;
  margin-left: 10%;
  margin-top: 5%;
  color: white;
}

.auth-intro-text-reset {
  width: 80%;
  text-align: justify;
  margin-left: 10%;
  margin-top: 5%;
  color: white;
}



@media (max-width: 768px) { 


.auth-intro-text {
  width: 100%;
  font-size: 12px;
  position: relative;
  bottom: 80px;
  margin-left: 0%;
  margin-top: 0%;
  text-align: justify;
  color: white;
}

.auth-intro-text-home {
  width: 88%;
  font-size: 12px;
  position: relative;
  bottom: 180px;
  margin-left: 10%;
  margin-top: 0%;
  text-align: justify;
  color: white;
}

.auth-intro-text-signup {
  width: 100%;
  font-size: 12px;
  position: relative;
  margin-left: 0%;
  top: -150px;
  margin-top: 0%;
  margin-bottom: 0px !important;
  text-align: justify;
  color: white;
}

.auth-intro-text-reset {
  width: 100%;
  font-size: 12px;
  position: relative;
  margin-left: 0%;
  bottom: 100px;
  margin-top: 0%;
  margin-bottom: 0px !important;
  text-align: justify;
  color: white;
}

.signuplogo {
  margin-top: 150px !important;
}

}


@media (max-width: 1230px) { 
  .feed .feed-container .feed-left a {
    font-size: 12px;
  }
}

@media (max-width: 1030px) { 
  .feed .feed-container .feed-left a {
    font-size: 10px;
  }
}

.show-text-on-desktop {
  display: block;
}

.show-text-on-mobile {
  display: none;
}

.meta-text-paragraph {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) { 
  .show-text-on-desktop {
    display: none !important;
  }

  .signup {
    background-image: none !important;
  }

  .form-panel-modi {
    bottom: 60px;
  }

  .show-text-on-mobile {
    display: block !important;
    position: relative;
    top: 180px;
  }

}