.metadata-card {
    display: flex;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-width: 400px;
    margin: 0 auto;
    padding: 16px;
  }
  
  .metadata-card-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 16px;
    margin-bottom: 16px;
  }
  
  .metadata-card-content {
    flex-grow: 1;
  }
  
  .metadata-card-title {
    font-size: 18px;
    margin-bottom: 8px;
  }
  
  .metadata-card-description {
    font-size: 14px;
    margin-bottom: 16px;
  }
  
  .metadata-card-site {
    display: flex;
    align-items: center;
  }
  
  .metadata-card-favicon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  
  .metadata-card-sitename {
    font-size: 14px;
  }
  